<template>
  <div class="pledgeitem-style">
    <div class="bigbox">
      <van-checkbox-group
        checked-color="#323232"
        v-model="result"
        :max="max"
        @change="cchange"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('text.t39')"
          :offset="10"
          @load="getlist"
        >
          <el-card class="box-card" v-for="item in list" :key="item.id">
            <div class="between-center">
              <div class="card-left">
                <van-checkbox :name="item.id">
                  <template #icon="props">
                    <img
                      v-if="props.checked"
                      class="itembox-pos-icon"
                      src="@/assets/img/img50.png"
                    />
                    <img
                      v-else
                      class="itembox-pos-icon"
                      src="@/assets/img/img49.png"
                    />
                  </template>
                </van-checkbox>
              </div>
              <div class="card-right between-center">
                <img :src="$http.imgurl + item.image" alt="" class="card-i1" />
                <div class="cr-b1">
                  <div class="cr-b1-t1">{{item.title}}#{{ item.tokenid }}</div>
                  <div class="cr-b1-t2" v-if="type == 'p'">{{$t('text.t188')}}</div>
                  <div class="cr-b1-t2" v-else-if="type == 'r'">{{$t('text.t189')}}{{item.day}}{{$t('text.t185')}}</div>
                </div>
                <!-- @click="goAhead('/pledgedetail/p')" -->
                <div
                  class="cr-b2"
                  v-if="type == 'p'"
                  
                >
                  <!-- {{$t('text.t77')}} -->
                </div>
                <div class="cr-b3" v-else-if="type=='r'">
                  {{item.is_redeem==1?$t('text.t190'):$t('text.t191')}}
                  <div class="crb3t1" v-if="item.surplus == 0">{{$t('text.t361')}}</div>
                  <div class="crb3t1" v-else>{{item.surplus}}{{$t('text.t362')}}</div>
                </div>
              </div>
            </div>
          </el-card>
        </van-list>
      </van-checkbox-group>
    </div>
    <div class="bot-box evenly-center" v-if="result.length > 0" v-loading="isloading">
      <div class="botbox-b1 center-center" v-if="type == 'p'" @click="pledgeNft">{{$t('text.t10')}} ({{result.length}})</div>
      <div class="botbox-b1 center-center" v-else-if="type == 'r'" @click="pledgeNft">{{$t('text.t176')}} ({{result.length}})</div>
      <div class="botbox-b2 center-center" @click="cancel">{{$t('text.t52')}}</div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components: {},
  data() {
    return {
      result: [],
      resultstr:"",
      type: "",
      isloading:false,
      chose:'',
      max:0,

      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,

      tmsg:''
    };
  },
  created() {
    this.chose = this.$route.query.type
    this.max = this.$route.query.max ?? this.config.max_number
    console.log(this.max);
    this.type = this.$route.params.type;
  },
  computed: {
    ...mapState(["language","config"])
  },
  mounted() {},
  methods: {
    cancel(){
      this.result = []
    },
    async pledgeNft(){
      try {
        this.isloading = true
        let arg = {
          type:this.type=='p'?0:this.type=='r'?1:'',
          ids:this.resultstr,
          language:this.language
        }
        const res = await this.$http.post('/redeem/batchPledgeRedeem',arg)
        const {address,tokenid,orderno,indexs} = res.data
        const tokenidArr = tokenid.toString().split(',').map(ele=> Number(ele))
        console.log(tokenidArr);
        if(this.type=='p'){
          await this.checkAllowance()
          this.depositall(address,tokenidArr,orderno)
        } else if(this.type=='r'){
          const indexArr = indexs.split(',').map(ele=> Number(ele))
          console.log(indexArr);
          this.withdrawall(address,indexArr,tokenidArr,orderno)
        }
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(){
      const user_address = this.$local.get('shop:account');
			const web3 = new this.Web3(ethereum);
			const contract_from = this.config.true; // 授权的合约地址
			const abi_from = JSON.parse(this.config.true_abi);
      const contract_to = this.config.pledge; // 授权的合约地址
			
			const contract_proto = new web3.eth.Contract(abi_from, contract_from,{from:user_address});
			const isAllowance = await contract_proto.methods.isApprovedForAll(user_address,contract_to).call();
      if(isAllowance){
			  return true
      } else{
			  const result1 = await contract_proto.methods.setApprovalForAll(contract_to,true).send();
			  console.log(result1);
        return true
      }
		},
    depositall(address,tokenidArr,orderno){
			const web3 = new this.Web3(ethereum);
      const contract_to = this.config.pledge;
      const abi_to = JSON.parse(this.config.pledge_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      console.log(nft_contract);
      nft_contract.methods.depositAll(address,tokenidArr).send().on('transactionHash',async (hash)=>{
				const rest = await this.sendHash(hash,orderno,2)
        this.tmsg = ''
        this.tmsg = rest.msg
			}).on('receipt',(receipt)=>{
        console.log(receipt);
        this.isloading = false
				this.$toast(this.tmsg)
        // this.clearlist()
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000);
			}).on('error', (error, receipt)=>{
				console.log(error,receipt);
        this.isloading = false
			})
		},
    withdrawall(address,indexs,tokenidArr,orderno){
			const web3 = new this.Web3(ethereum);
      const contract_to = this.config.pledge;
      const abi_to = JSON.parse(this.config.pledge_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      console.log(nft_contract);
      nft_contract.methods.restoreDepositByAddress(address,indexs,false).send().on('transactionHash',async (hash)=>{
				const rest = await this.sendHash(hash,orderno,3)
        this.tmsg = ''
        this.tmsg = rest.msg
			}).on('receipt',(receipt)=>{
        console.log(receipt);
        this.isloading = false
				this.$toast(this.tmsg)
        // this.clearlist()
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000);
			}).on('error', (error, receipt)=>{
				console.log(error,receipt);
        this.isloading = false
			})
		},
    async sendHash(hash,orderno,type){
			const arg = {
				hash,
				orderno,
				type,
				language:this.language
			}
			return await this.$http.post('/redeem/addHash',arg)
		},
    cchange() {
      this.resultstr = this.result.join(',')
      console.log(this.resultstr);
    },
    clearlist(){
      this.result = []
      this.finished = false
      this.page = 1
      this.list = []
      this.loading = false
      this.getlist()
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
      };
      this.$http.get(this.type == "p" ? "/redeem/buyPledgeList" : "/redeem/pledgeList",arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.loading = false;
        this.list = [...this.list, ...data];
        if(this.chose == 1 && this.list.length > 0 && this.page == 1){
          if(this.list.length <= this.max){
            this.result = this.list.map(ele=>ele.id)
          } else{
            this.result = this.list.filter((ele,index)=>index < this.max).map(eles=>eles.id)
          }
          console.log(this.result);
        } else if(this.chose == 2 && this.list.length > 0 && this.page == 1){
          this.result = this.list.filter(ele=>ele.is_redeem == 1).map(eles=>eles.id)
          console.log(this.result);
        }
        this.page++;
      });
    },
  },
};
</script>

<style lang="less">
.pledgeitem-style {
  .bigbox {
    padding: 21px 0 61px;
    .box-card {
      width: 300px;
      border-radius: 10px;
      margin: 0 auto 20px;
      .el-card__body,
      .el-main {
        padding: 10px 15px;
      }
      .card-left {
        margin-right: 15px;
        .itembox-pos-icon {
          width: 16px;
          height: 16px;
        }
      }
      .card-right {
        flex: 1;
        .card-i1 {
          width: 33px;
          height: auto;
          margin-right: 10px;
        }
        .cr-b1 {
          flex: 1;
          font-weight: bold;
          color: #323232;
          .cr-b1-t1 {
            font-size: 15px;
            margin-bottom: 12px;
          }
          .cr-b1-t2 {
            font-size: 12px;
          }
        }
        .cr-b2 {
          font-size: 12px;
          font-weight: 500;
          color: #1359b0;
          margin-left: 6px;
        }
        .cr-b3 {
          text-align: right;
          font-size: 12px;
          font-weight: 600;
          color: #323232;
          margin-left: 6px;
          .crb3t1{
            font-size: 10px;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .bot-box {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -6px 10px rgba(0, 0, 0, 0.1);
    .botbox-b1 {
      width: 130px;
      height: 30px;
      background: #323232;
      border-radius: 15px;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
    }
    .botbox-b2 {
      width: 131px;
      height: 31px;
      border: 1px solid #323232;
      border-radius: 15px;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
    }
  }
}
</style>